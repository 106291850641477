

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    user-select: none;
}

input:focus {
    border: none;
}

input::selection {
    border: none;
}

.wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}

.calculator-wrppaer {
    display: flex;
    justify-content: space-between;
    margin-top: 75px;
}

.calculator-wrppaer>div {
    position: relative;
    height: 761px;
    border-radius: 30px;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.11);
    overflow: hidden;
}

.calculator-left {
    width: 667px;
    padding: 60px;
    padding-bottom: 42px;
}

.calculator-one {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calculator-two {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calculator-scroll {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    touch-action: none;
}

.calculator-scroll::-webkit-scrollbar {
    width: 0;
}

.category-icon {
    width: 25px;
    height: auto;
    margin-right: 15px;
    user-select: none;
}

.input-wrapper {
    position: relative;
}

.last-input-wrapper {
    margin-bottom: 0;
}

.input-flex {
    display: flex;
    align-items: center;
    user-select: none;
}

.input-wrapper>.input-flex:last-of-type {
    margin-top: 20px;
}

.input-wrapper p {
    font-size: 20px;
    font-weight: 500;
    user-select: none;
}

.select-wrapper {
    position: relative;
    width: 240px;
    margin: 0 16px;
    ;
}

.radio-wrapper{
    position: relative;
    width: 240px;
    z-index: 9;
}
.radio-trigger{
    width: 240px;
    height: 40px;
    background: #EDF4FF;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    position: relative;
    color: #b1b1b1;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.radio-wrapper-arrow{
    position: absolute;
    right: 15px;
    top:calc(50% - 7px);
    transform: translateY(-50%);
    content: '';
    width: 9px;
    height: 9px;
    border-left: 2px solid #808080;
    border-bottom: 2px solid #808080;
    transform: rotate(315deg);
    transition: 0.3s;
}
.radio-wrapper-arrow.arrow-lotate{
    top: calc(50% - 3px);
    transform: rotate(135deg);
}
.diet-input {
    position: absolute;
	counter-increment: radios;
	appearance: none;
	display: none;
    font-size: 18px;
    font-weight: 600;
    user-select: none;
    width: 1px;
    height: 1px;
}

.diet-input-default{
    
}

.caution-text{
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    color: #F34744;
    top:calc(100% + 4px);
    left: 0;
    opacity: 0;
}
.caution-on{
    opacity: 1;
}

.select-box{
	width: 100%;
	background: #EDF4FF;
	position: absolute;
    top: calc(100% + 4px);
	left: 0;
	box-sizing: border-box;
    border-radius: 10px;
	overflow-y: auto;
    z-index: 10;
    height: 0;
    opacity: 0;
    transition: all .3s;
}
.select-box::-webkit-scrollbar{
    width:0;
}
.select-box-slide{
    height: 160px;
    opacity: 1;
}

.select-box li:hover {
    background-color: #BCD1F3;
}
.select-box li:hover label{
    font-weight: 600;
    color: #191919;
    user-select: none;
}

.select-box label{
    display: block;
    width: 100%;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: #4d4d4d;
    padding: 9px 15px;
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animation: 0.2s;
    background: #e6e6e6;
    border-radius: 25px;
}

input[type=range]::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #BCD1F3;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
}

.range-wrapper {
    width: 320px;
    height: 30px;
    position: relative;
    margin-right: 16px;
}

.range-value {
    position: absolute;
    top: -58%;
    left:12px;
    transform: translateY(-50%);
}

.range-value span {
    height: auto;
    padding: 0 4px;
    text-align: center;
    color: var(--main-color);
    font-size: 16px;
    font-weight: 600;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    user-select: none;
}

.next-step {
    display: flex;
    padding-bottom: 4px;
}

.next-step-btn {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.next-step-btn p {
    font-size: 16px;
    font-weight: 500;
    color: #808080;
    user-select: none;
    transition: 0.3s;
}

.next-step-btn span {
    width: 13px;
    height: 13px;
    border-left: 2px solid #808080;
    border-bottom: 2px solid #808080;
    transform: rotate(315deg);
    transition: 0.3s;
    margin-top: 10px;
}
.next-step-btn:hover p{
    color: #191919;
}

.next-step-btn:hover span{
    border-left: 2px solid #191919;
    border-bottom: 2px solid #191919;
}

.calculator-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calculator-button-wrapper>button {
    display: block;
    width: 260px;
    height: 64px;
    border-radius: 500px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    transition-duration: .3s;

}

.calculator-reset {
    background-color: #b1b1b1;
}
.calculator-reset:hover{
    background-color: #808080;
}

.calculator-submit {
    background-color: var(--main-color);
}
.calculator-submit:hover{
    background-color: #314AC1;
}
.calculator-right {
    width: 587px;
}

.calculator-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    padding: 60px;
    user-select: none;
    display: block;
    opacity: 1; 
}
.cover-loading{
    opacity: 0;
    height: 0;
    transition-duration: .5s;
}

.calculator-cover strong {
    display: block;
    font-size: 42px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 28px;
}

.calculator-cover p {
    font-size: 20px;
    font-weight: 300;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 24px;
}

.cover-image {
    position: absolute;
    right: 55px;
    bottom: 52px;
    width: 170px;
    transform: rotate(5deg);
    transition-duration: .1s;
}
.calculator-result {
    height: 100%;
    padding: 60px;
    padding-bottom: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.result-value {
    font-size: 42px;
    font-weight: 600;
    line-height: 114%;
    margin-bottom: 60px;

}

.result-value span {
    font-size: 60px;
    font-weight: 700;
    color: var(--main-color);
    line-height: 114%;
}

.result-image {
    max-width: 261px;
    width: 100%;
    margin-bottom: 60px;
    user-select: none;
}

.result-text {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
    line-height: 25px;
}

.result-text span {
    font-weight: 700;
    color: var(--main-color);
}

.result-tip {
    font-size: 14px;
    font-weight: 500;
    color: #F34744;
}
